import React, { FunctionComponent } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { INoticia } from './interfaces';
import { useStyles } from './styles';
import { sanitizeHtml } from '../../utils/functions';

moment.locale('es');

interface ICustomCardProps {
  noticia: INoticia
}

const CustomCard: FunctionComponent<ICustomCardProps> = ({ noticia }) => {
  const classes = useStyles();

  return (
    <Card elevation={4}>
      <CardContent className={classes.cardContent}>
        <div className={classes.image}>
          <div></div>
          <img src={`data:image/jpeg;base64,${noticia.imagen}`} />
        </div>
        <Grid container spacing={2} direction="column" className={classes.info}>
          <Grid item className={classes.cardTitle}>
            <Typography variant="body1">{sanitizeHtml(noticia.titulo)}</Typography>
          </Grid>
          <Grid item className={classes.date}>
            <Typography variant="subtitle2">{moment(noticia.fecha).format('DD [de] MMMM [de] YYYY')}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomCard;