import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  cardLink: {
    '&:hover': {
      '& img': {
        transform: 'scale(1.2, 1.2)'
      }
    }
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  cardGroup: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  cardContent: {
    padding: '0px !important',
    position: 'relative'
  },
  image: {
    position: 'relative',
    '& > div': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(0deg, #0c0c0c 0%, rgba(12,12,12,0.0987745) 80%)",
    },
    '& > img': {
      display: 'block',
      objectFit: 'cover',
      width: '100%',
      height: 280,
      transition: 'all .4s ease-in-out'
    }
  },
  info: {
    padding: theme.spacing(3),
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 99,
    color: '#fff'
  },
  cardTitle: {
    '& > p': {
      fontWeight: 'bold'
    }
  },
  date: {
    textAlign: 'right',
    color: grey[400]
  }
}));