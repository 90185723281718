import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'gatsby'
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { encodeQueryData } from '../../utils/functions';
import CustomCard from './CustomCard';
import { INoticia } from './interfaces';
import { getNoticias } from './functions';
import { useStyles } from './styles';

const News = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [noticias, setNoticias] = useState<INoticia[]>([]);

  useEffect(() => {
    let unmounted = false;
    const source = Axios.CancelToken.source();
    const getData = async () => {
      try {
        setLoading(true);
        const data: Partial<INoticia> = {
          activo: 1,
          borrado: false
        }
        const resp = await getNoticias(encodeQueryData(data), source.token);
        if (!unmounted) {
          setNoticias(resp);
          setLoading(false);
        }
      } catch (error) {
        if (!unmounted) console.log(error);
      }
    }
    getData();

    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h2">Noticias</Typography>
      </Grid>

      {loading ? (
        <Grid item xs={12}>
          <CircularProgress color='primary' />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <div className={classes.cardGroup}>
            {noticias && noticias.length === 0 ? <Typography>No hay noticias por mostrar</Typography> : null}

            {noticias && noticias.length > 0 ? noticias.map((noticia, index) => (
              <div key={index}>
                <Link to='/noticia/' state={{ noticia: noticia }} className={classes.cardLink}>
                  <CustomCard noticia={noticia} />
                </Link>
              </div>
            )) : null}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default News;