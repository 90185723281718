import { CancelToken } from "axios";
import { axiosPortal } from "../../utils/constants";
import { handleAxiosError } from "../../utils/functions";
import { INoticia } from "./interfaces";

export const getNoticias = async (search: string, cancelToken: CancelToken) => {
  try {
    const url = `/noticias`;
    const resp = await axiosPortal.get(url, {
      cancelToken
    });
    return resp.data as INoticia[];
  } catch (error) {
    if (!cancelToken) handleAxiosError(error);
  }
}