import { Theme, createStyles, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Slider, { Settings as SlickSettings } from "react-slick"
import "../../assets/styles/styles.scss"
import {
  defaultSliderSettings,
  infraestructuras,
  veda_electoral
} from "../../utils/constants"
import { getApiUrl } from "../../utils/functions"
import { ISliderItem } from "../../utils/interfaces"
import DialogInfo from "./DialogInfo"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainSlider: {
      "& *": {
        outline: "none",
      },
      "& img": {
        width: "100%",
        maxHeight: 386,
      },
    },
  })
)

const MainSlider = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<any>()
  const [isGallery, setIsGallery] = useState(false)
  const [loading, setLoading] = useState(true)

  const sliderSettings: SlickSettings = {
    ...defaultSliderSettings,
    autoplay: !open,
    speed: 500,
    
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 
    10)
  }, [])

  const items: ISliderItem[] = [   
    {
      to:"https://play.google.com/store/apps/details?id=com.app.isset&pcampaignid=web_share",
      name: "Aplicacion movil",
      show: true,
      cover: require("../../assets/images/banners/app_movil.jpeg"),
    },
    {
      to: "https://congreso2024.isset.gob.mx:8081",
      name: "Banner 5",
      show: true,
      cover: require("../../assets/images/banners/banner_congreso_2024.jpg"),
    },
   /* {
      to: "/servicios-enlinea/portal-creditos",
      name: "prestamo_pcp",
      show: true,
      cover: require("../../assets/images/banners/prestamo_pcp.jpg"),
    },*/
    {
      to: "https://presmed.isset.gob.mx/login",
      name: "Banner 5",
      show: true,
      cover: require("../../assets/images/banners/4-presmed.jpg"),
    },
    {
      to: "https://tramites.isset.gob.mx:6001/tramites/login",
      name: "Banner 6",
      show: true,
      cover: require("../../assets/images/banners/5-tramites.jpg"),
    },
    {
      to: "/proveedores",
      inside: true,
      name: "Portal de Proveedores",
      show: true,
      cover: require("../../assets/images/banners/banner-proveedores.jpg"),
    },  
    {
      to: `${getApiUrl("portal")}/static/docs/banners/FUNERARIA-ISSET.jpg`,
      name: "Banner 4",
      show: true,
      modal: true,
      cover: require("../../assets/images/banners/banner-funeraria.jpg"),
    },
    {
      to: infraestructuras,
      name: "Banner 9",
      gallery: true,    
      show: !veda_electoral,
      cover: require("../../assets/images/banners/8-infraestructura.jpg"),
    },
    {
      to:
        "http://www.isset.gob.mx/suggestions-mailbox",
      name: "Buzón de sugerencias e ISSETel",
      show: true,
      cover: require("../../assets/images/banners/banner-issetel.jpg"),
    },
    // {
    //   to:
    //     "https://cemer.tabasco.gob.mx/agenda-regulatoria",
    //   name: "Consulta Pública de Agenda Regulatoria",
    //   show: true,
    //   cover: require("../../assets/images/banners/agenda_regulatoria_2024.jpg"),
    // } ,
    // {
    //   to:
    //     "https://cemer.tabasco.gob.mx/lista-de-programas",
    //   name: "Consulta Pública de los Programas de Mejora Regulatoria",
    //   show: true,
    //   cover: require("../../assets/images/banners/programas_regulatoria_2024.jpg"),
    // } 
    
    /*quitar temporalmente no. oficio: DPPIE/2282/2022
    {
      to: `${getApiUrl("portal")}/static/docs/banners/6-equipo-isset.pdf`,
      name: "Banner 7",
      show: !veda_electoral,
      cover: require("../../assets/images/banners/6-equipo-isset.jpeg"),
    },
    */        
  ]

  const handleOpenDialog = (data: string, isGallery: boolean = false) => {
    setOpen(true)
    setData(data)

    if (isGallery) {
      setIsGallery(true)
    }
  }

  if (loading) return <>Cargando...</>
  return (
    <>
      <DialogInfo
        open={open}
        setOpen={setOpen}
        data={data}
        isGallery={isGallery}
        setIsGallery={setIsGallery}
      />

      <Slider  autoplaySpeed={12000} {...sliderSettings} className={classes.mainSlider}>
        {items
          .filter(item => item.show)
          .map((item, index) => (
            <div key={index}>
              {item.modal ? (
                <img
                  src={item.cover}
                  style={{ cursor: "pointer" }}
                  alt={item.name}
                  onClick={e => handleOpenDialog(item.to)}
                />
              ) : null}

              {item.gallery ? (
                <img
                  src={item.cover}
                  style={{ cursor: "pointer" }}
                  alt={item.name}
                  onClick={e => handleOpenDialog(item.to, true)}
                />
              ) : null}

              {item.to && !item.modal && !item.gallery ? (
                <a
                  href={item.to}
                  target={item.inside ? "_self" : "_blank"}
                  rel={item.inside ? "" : "noopener noreferrer"}
                >
                  <img src={item.cover} alt={item.name} />
                </a>
              ) : null}

              {!item.modal && !item.gallery && !item.to && item.onClick ? (
                <img src={item.cover} alt={item.name} onClick={item.onClick} />
              ) : null}

              {!item.to && !item.modal && !item.gallery && !item.onClick ? (
                <img
                  src={item.cover}
                  style={{ cursor: "pointer" }}
                  alt={item.name}
                />
              ) : null}
            </div>
          ))}
      </Slider>
    </>
  )
}

export default MainSlider
