import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FacebookProvider, Page } from 'react-facebook';

import Layout from "../components/layout";
import News from "../components/news";
import SEO from "../components/seo";
import Adds from "../components/sliders/adds";
import MainSlider from "../components/sliders/mainSlider";

import { mdiFile } from "@mdi/js";
import Icon from "@mdi/react";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useStyles } from '../utils/inicio/styles';

const Index = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  
  return (
    <Layout>
      <SEO title="Inicio" />

      <MainSlider />
{/*
      <Dialog  open={open} onClose={() => { setOpen(false) }}   >
        <DialogTitle>Aviso Importante Pensionado ISSET</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
        <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href={`${getApiUrl("portal")}/static/docs/anuncios/PENSIONADOS-SIN-CSF.pdf`} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img width={530} height={530}  src={require('../assets/images/anuncios/pensionados_isset.jpg')} />
                </Grid>       
              </Grid>       
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>   
        </Dialog> 
  */ }
  {/*
       <Dialog open={open} onClose={() => { setOpen(true) }} fullWidth>
        <DialogTitle>ISSET en línea contigo</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <video
            width="560"
            height="315"
            src={`${getApiUrl("portal")}/static/docs/anuncios/SPOT-ISSET-EN-LINEA-ISSETEL.mp4`}        
            title="ISSET EN LÍNEA CONTIGO"            
            autoPlay
            muted
            controls  
          //  muted
        ></video>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> 
*/}
      <div style={{ padding: 16, marginTop: 50 }}>
        <Grid container>
          <Grid item xs={12} lg={8} container alignItems="flex-start" className={`${classes.info} ${classes.infoTop}`}>
            <Grid item xs={12} md={6} container spacing={2} className={classes.doctor} wrap='nowrap'>
              <Grid item>
                <img src={require('../assets/images/doctor/LC-Alipi-Mena.png')} />
              </Grid>
              <Grid item style={{ maxWidth: 330 }}>
                <Typography variant="h6" component="p" color="primary">L.C. Carlos Rafael de Jesús Alipi Mena</Typography>

                <a href="mailto:carlosalipi@isset.gob.mx" target="_blank" rel="noopener noreferrer">carlosalipi@isset.gob.mx</a>
                {/*
                <a href="mailto:fernandomayans@isset.gob.mx" target="_blank" rel="noopener noreferrer">fernandomayans@isset.gob.mx</a>
                <a href="mailto:fernandomayans@tabasco.gob.mx" target="_blank" rel="noopener noreferrer">fernandomayans@tabasco.gob.mx</a>
                */}
                <Typography variant="body1">Av. 27 de Febrero No. 930 Col. Centro, C.P. 86000, Villahermosa, Tabasco, MX</Typography>

                <a href="tel:9933582850" target="_blank" rel="noopener noreferrer">993 358 2850</a> <span>Ext 63003</span>

                <Typography variant="body1">Lunes a Viernes de 8:00 a 15:00 hrs.</Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} container spacing={2} className={classes.docs}>
              {/* transparencia estatal */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='https://transparencia.tabasco.gob.mx/ciudadano/lista_fracciones/58/5/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <Icon path={mdiFile} style={{ width: 98, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.55 }} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1">Transparencia estatal</Typography>
                </Grid>
              </Grid>
              {/* transparencia nacional */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.plataformadetransparencia.org.mx/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 98, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/transparencia-nacional.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >Transparencia nacional</Typography>
                </Grid>
              </Grid>
              {/* itaip */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.itaip.org.mx/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 49, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/itaip.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >ITAIP</Typography>
                </Grid>
              </Grid>
              {/* infomex */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.infomextabasco.org.mx/v25/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 56, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/infomex.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >Infomex</Typography>
                </Grid>
              </Grid>
              {/* fin */}
            </Grid>
          </Grid >

          <Grid item xs={2} style={{marginLeft:100}} container className={classes.socialMedia}>
           
            {/* <Grid  item xs={12} md={6} lg={12}>
              <Timeline  dataSource={{ sourceType: 'profile', screenName: 'Isset_Tab' }} options={{height: 320, width: 320, lang: 'es', theme: 'dark', chrome: 'noborders noheader nofooter' }} />
            </Grid> 
            
             <Grid item xs={12} md={6} lg={12} >
              <FacebookProvider appId="781742835694082">
                <Page href="https://www.facebook.com/issetTabasco" tabs="timeline" height={320} width={320} smallHeader={true} hideCover={false} showFacepile={false} frameborder={0} adaptContainerWidth={false} />
              </FacebookProvider>
            </Grid>
            */}
            

            <Grid item xs={12} md={6} lg={12} >
              <FacebookProvider appId="781742835694082">
                <Page href="https://www.facebook.com/issetTabasco" tabs="timeline" height={640} width={320} smallHeader={true} hideCover={false} showFacepile={false} frameborder={0} adaptContainerWidth={false} />
              </FacebookProvider>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={8} container alignItems="flex-start" className={`${classes.info} ${classes.infoBottom}`}>
            <Grid item xs={12} md={7} container spacing={2} className={classes.doctor} wrap='nowrap'>
              <Grid item>
               {/*<img src={require('../assets/images/doctor/dr-mayans-canabal.png')} />*/}                
                <img src={require('../assets/images/doctor/LC-Alipi-Mena.png')} />
              </Grid>
              <Grid item style={{ maxWidth: 330 }}>
                <Typography variant="h6" component="p" color="primary">L.C. Carlos Rafael de Jesús Alipi Mena</Typography>
                <a href="mailto:carlosalipi@isset.gob.mx" target="_blank" rel="noopener noreferrer">carlosalipi@isset.gob.mx</a>
                {/*
                <a href="mailto:fernandomayans@isset.gob.mx" target="_blank" rel="noopener noreferrer">fernandomayans@isset.gob.mx</a>
                <a href="mailto:fernandomayans@tabasco.gob.mx" target="_blank" rel="noopener noreferrer">fernandomayans@tabasco.gob.mx</a>
                */}
                <Typography variant="body1">Av. 27 de Febrero No. 930 Col. Centro, C.P. 86000, Villahermosa, Tabasco, MX</Typography>

                <a href="tel:9933582850" rel="noopener noreferrer">993 358 2850 <span>Ext 63003</span></a>

                <Typography variant="body1">Lunes a Viernes de 8:00 a 15:00 hrs.</Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={5} container spacing={2} className={classes.docs}>
              {/* transparencia estatal */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='https://transparencia.tabasco.gob.mx/ciudadano/lista_fracciones/58/5/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <Icon path={mdiFile} style={{ width: 98, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.55 }} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1">Transparencia estatal</Typography>
                </Grid>
              </Grid>
              {/* transparencia nacional */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.plataformadetransparencia.org.mx/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 98, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/transparencia-nacional.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >Transparencia nacional</Typography>
                </Grid>
              </Grid>
              {/* itaip */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.itaip.org.mx/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 49, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/itaip.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >ITAIP</Typography>
                </Grid>
              </Grid>
              {/* infomex */}
              <Grid item xs={6} sm={3} md={6} container direction="column" alignItems="center" component='a' href='http://www.infomextabasco.org.mx/v25/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Grid item>
                  <img style={{ width: 56, height: 55, objectFit: 'fill', filter: 'grayscale(1)', opacity: 0.8 }} src={require('../assets/images/doctor/infomex.png')} />
                </Grid>
                <Grid item>
                  <Typography color='textPrimary' variant="body1" >Infomex</Typography>
                </Grid>
              </Grid>
              {/* fin */}
            </Grid>

            <Grid item xs={12} style={{ marginTop: 50 }}>
              <Adds />
            </Grid>
          </Grid >
        </Grid >
      </div >

      {/* noticias */}
      < div style={{ padding: 16, marginTop: 50 }}>
        <News />
      </div >

    </Layout >
  )
}

export default Index
