import {
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Slider, { Settings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "../../assets/styles/styles.scss"
import { defaultSliderSettings, veda_electoral } from "../../utils/constants"
import { getApiUrl } from "../../utils/functions"
import { ISliderItem } from "../../utils/interfaces"
import DialogInfo from "./DialogInfo"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adds: {
      "& > div:nth-child(1)": {
        marginBottom: theme.spacing(2),
      },
    },
    addsSlider: {
      "& *": {
        outline: "none",
      },
      "& img": {
        height: 240,
        maxWidth: 220,
        margin: theme.spacing(2),
        transition: "transform .3s ease",
        transform: "scale(0.95)",
        "&:hover": {
          transform: "scale(1)",
        },
      },
      "& div.slick-current img": {
        transform: "scale(1.1)",
        "&:hover": {
          transform: "scale(1.15)",
        },
      },
    },
  })
)

const Adds = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState("")
  const [loading, setLoading] = useState(true)

  const [isVideo, setIsVideo] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  const addsSlider: Settings = {
    ...defaultSliderSettings,
    centerMode: true,
    autoplay: false,
    slidesToShow: 5,
    variableWidth: true,
    adaptiveHeight: true,
    className: classes.addsSlider,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const items: ISliderItem[] = [
      
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/anuncio_app_movil.jpeg`,
      name: "App movil",  
      show: true,
      modal:true,
      cover: require("../../assets/images/anuncios/anuncio_app_movil.jpeg"),
    },
    {
      to: "https://www.isset.gob.mx/micita",
      name: "Agenda tu cita médica ISSET",  
      show: true,
      modal: true,
      cover: require("../../assets/images/anuncios/agenda-cita.png"),
    },
    /*{
      to: "/servicios-enlinea/portal-creditos",
      name: "Prestamo pcp",  
      show: true,
      cover: require("../../assets/images/anuncios/anuncio_pcp.jpeg"),
    }, */   
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/NOTIFICACION_FALLECIMIENTO.mp4`,
      name: "Video 1",         
      modal: true,
      isVideo: true,
      show: true,           
      cover: require("../../assets/images/anuncios/aviso_fallecimiento.jpg")
    },        

    {
      to: `${getApiUrl("portal")}/static/docs/tu-instituto/organos-colegiados/comite-etica/codigo-conducta/Codigo_Conducta_Prevencion_Conflicto_Interes_Personas_Servidoras_Publicas.pdf`,
      name: "Código de conducta",        
      show: true,
      cover: require("../../assets/images/anuncios/codigo_conducta.jpeg")
      
    },    
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/prestaciones_socioeconomicas.mp4`,
      name: "Antes-Ahora Prestaciones Socioeconómicas",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v15.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/Campaña-de-afiliacion-V2.mp4`,
      name: "Campaña de afiliación",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v1.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/SPOT-ISSET-EN-LINEA-ISSETEL.mp4`,
      name: "SPOT-ISSET-EN-LINEA-ISSETEL",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v2.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/juan-puig-palacio.mp4`,
      name: "Dr. Juan Puig Palacio",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v3.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/juan-puig-palacio-24hrs.mp4`,
      name: "Dr. Juan Puig Palacio 24hrs",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,       
      cover: require("../../assets/images/anuncios/v4.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/SPOT_servicios_issetel_rss.mp4`,
      name: "SPOT servicios",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v5.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/spot_funeraria_isset.mp4`,
      name: "SPOT servicios",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v6.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/Hipertension_2020.mp4`,
      name: "SPOT servicios",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v7.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/ISSET_DIABETES_VF_ALTA_cambio_2020.mp4`,
      name: "SPOT servicios",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v8.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/ISSET_evita_enfermedades_VF_ALTA_cambio_2020.mp4`,
      name: "SPOT servicios",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v9.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/Servicios_CECUIDAM_v2.mp4`,
      name: "SPOT servicios issetel",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v10.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/LAVADO_MANOS_V3.mp4`,
      name: "SPOT servicios issetel",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v11.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/ISSETCANCER_VF_ALTA_cambio_2020.mp4`,
      name: "SPOT servicios issetel",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v12.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/Obesidad_2020.mp4`,
      name: "SPOT servicios issetel",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v13.jpg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/SPOT_UMC 2022.mp4`,
      name: "SPOT servicios issetel",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/v14.jpg")
    },

    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/atencion-medica.jpeg`,
      name: "Unidad de admision médica continua",  
      modal: true,  
      show: true,      
      cover: require("../../assets/images/anuncios/atencion-medica.jpeg")
    },

    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/cecuidam-isset.jpeg`,
      name: "CECUIDAM",  
      modal: true,  
      show: true,      
      cover: require("../../assets/images/anuncios/cecuidam-isset.jpeg")
    },

    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/afiliate-cecuidam.jpeg`,
      name: "Afiliarte al ISSET",  
      modal: true,  
      show: true,      
      cover: require("../../assets/images/anuncios/afiliate-cecuidam.jpeg")
    },

    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/ALFINESVIERNES.mp4`,
      name: "Al Fin es Viernes",         
      modal: true,
      isVideo: true,
      show: !veda_electoral,           
      cover: require("../../assets/images/anuncios/alfinesviernes.jpg"),
    },

    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/3.jpeg`,
      name: "Anuncio 3",
      modal: true,
      show: !veda_electoral,
      cover: require("../../assets/images/anuncios/3.jpeg"),
    },     
    
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/10.png`,
      name: "Anuncio 10",
      modal: true,
      show: true,
      cover: require("../../assets/images/anuncios/10.png"),
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/funeraria-isset.jpeg`,
      name: "Funeraria ISSET",  
      //modal: true,  
      show: true,      
      cover: require("../../assets/images/anuncios/funeraria-isset.jpeg")
    },
    {
      to: `${getApiUrl("portal")}/static/docs/anuncios/issetel.jpeg`,
      name: "ISSETEL",  
      modal: true,  
      show: true,      
      cover: require("../../assets/images/anuncios/issetel.jpeg"),
    }
  ]

  const handleOpenDialog = (data: string) => {
    setOpen(true)
    setData(data)
  }

  if (loading) return <>Cargando...</>
  return (
    <>
      <DialogInfo open={open} setOpen={setOpen} data={data} isVideo={isVideo}/>

      <Grid container direction="column" className={classes.adds}>
        <Grid item xs={12}>
          <Typography variant="h2">Anuncios</Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider {...addsSlider}>
            {items
              .filter(item => item.show)
              .map((item, index) => (
                <div key={index}>
                  {item.modal ? (
                    <img 
                      src={item.cover}
                      style={{ cursor: "pointer" }}
                      alt={item.name}
                      onClick={e => {
                        setIsVideo(item.isVideo? true: false)
                        handleOpenDialog(item.to)
                      }}
                    />
                  ) : null}

                  {item.to && !item.modal ? (
                    <a
                      href={item.to}
                      target={item.inside ? "_self" : "_blank"}
                      rel={item.inside ? "" : "noopener noreferrer"}
                    >
                      <img src={item.cover} alt={item.name} />
                    </a>
                  ) : null}

                  {!item.to && !item.modal ? (
                    <img
                      src={item.cover}
                      style={{ cursor: "pointer" }}
                      alt={item.name}
                    />
                  ) : null}
                </div>
              ))}
          </Slider>
        </Grid>
      </Grid>
    </>
  )
}

export default Adds
