import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  socialMedia: {
    maxWidth: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    '& > div:nth-child(2)': {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      '& > div:nth-child(1)': {
        paddingRight: theme.spacing(2),
        textAlign: 'right'
      },
      '& > div:nth-child(2)': {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
        textAlign: 'left'
      }
    },
    [theme.breakpoints.up('lg')]: {
      order: 2,
      '& > div': {
        textAlign: 'right !important',
        padding: '0px !important'
      },
      '& > div:nth-child(2)': {
        marginTop: theme.spacing(2)
      }
    }
  },
  info: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      order: 1
    }
  },
  doctor: {
    flexDirection: 'column',
    fontSize: theme.typography.body1.fontSize,
    marginTop: theme.spacing(4),
    '& a': {
      display: 'block',
      color: theme.palette.text.primary
    },
    '& > div:nth-child(1) > img': {
      width: 245,
      height: 180
    },
    '& > div:nth-child(2) > *:first-child': {
      marginTop: 0,
      fontWeight: 'bold'
    },
    '& > div:nth-child(2) > p, & > div:nth-child(2) > a': {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.up(460)]: {
      flexDirection: 'row',

    }
  },
  docs: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  infoTop: {
    display: 'flex',
    marginTop: 0,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  infoBottom: {
    '& > div:nth-child(1), & > div:nth-child(2)': {
      display: 'none',
      marginTop: 0
    },
    [theme.breakpoints.up('lg')]: {
      '& > div:nth-child(1), & > div:nth-child(2)': {
        display: 'flex'
      }
    }
  }
}));